.CreateProfileSection {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: "Nunito", sans-serif;
}

.CreateProfileSection h1 {
  font-size: 26pt;
}

.VerticalFlex {
  display: flex;
  flex-direction: column;
}

.CreateProfileCheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16pt;
  text-align: left;
  gap: 30px;
}

.CreateProfileCheckbox {
  width: 30px;
  height: 30px;
  border: 3px solid #96695e;
  border-radius: 10px;
  /*-moz-appearance:none;*/
  /*-webkit-appearance:none;*/
}

.CreateProfileDarkButton {
  background: black;
  border-radius: 10px;
  align-self: stretch;
  height: 90px;
  color: white;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  font-size: 24pt;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CreateProfileSpinner {
  width: 80px;
  height: 80px;
  filter: invert(100%);
}

@media (prefers-color-scheme: dark) {
  .CreateProfileSpinner {
    filter: invert(0%);
  }
}

