.AdminHeader {
  display: flex;
  text-align: left;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  padding: 10px 20px 10px 20px;
  background-color: #aee5fc;
}

.BusinessSearchResultOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  border: 1px solid #aee5fc;
  background-color: white;
  z-index: 100000;
}

.BusinessSearchResult {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 400px;
  text-align: left;
}

.BusinessSearchResult:nth-child(2n) {
  background-color: #f4f4f4;
}

.BusinessSearchResult:hover {
  background-color: #dbf1ff;
}

