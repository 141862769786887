.PendingReviewBanner {
  background-color: #ffe24b;
  border: 1px solid black;
}

.WebsiteSubmissionStatePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  gap: 20px
}

.WebsiteSubmissionStateText {
  font-size: 16px;
  max-width: 600px;
  padding: 10px;
  text-align: center;
}

.CompleteProfileChecklist {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.CompleteProfileChecklistHeader {
  text-align: center;
}

.CompleteProfileChecklistItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.CompleteProfileChecklistItem h2 {
  margin-bottom: 3px;
}

.CompleteProfileChecklistButton {
  color: white;
  font-family: 'Nunito', sans-serif;
  background-color: #96695e;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  border: none;
}

.CompleteProfileChecklistButtonCompleted {
  color: black;
  font-family: 'Nunito', sans-serif;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  border: 1px solid black;
}

.CompleteProfileButtonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.CompleteProfileEditButton {
  width: 30px;
  height: 30px;
}