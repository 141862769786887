.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.Flex1 {
  flex: 1;
}

.Gap20 {
  gap: 20px;
}

.HorizontalFlex {
  display: flex;
  flex-direction: row;
}


/* This is a generic section with 100% width so the background color can be
   edge to edge but the content is limited by the AppContentWidth below. */
.AppSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.AppSectionContent {
  max-width: min(1366px, 100vw);
  align-items: center;
}

.BeigeSection {
  background-color: #F6F4F1;
  color: black;
}

.WhiteSection {
  background-color: #FFF;
  color: black;
}

.RedSection {
  background-color: #96695e;
  color: white;
}

.LightRedSection {
  background-color: #cb8e7e;
  color: black;
}

@media (prefers-color-scheme: dark) {
  .BeigeSection {
    background-color: #423E3A;
    color: white;
  }

  .BeigeSection a {
    color: white;
  }
}

.DarkSection {
  background-color: #423E3A;
  color: white;
}

.BlackSection {
  background-color: #000;
  color: white;
}

.Header {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 42px;
}

.StickyHeader {
  border-bottom: 1px solid #423E3A80;
}

@media (prefers-color-scheme: dark) {
  .StickyHeader {
    border-color: white;
  }
}


.HeaderLeftElement {
  display: flex;
  flex-direction: row;
  justify-items: start;
  justify-content: start;
  align-items: center;
  font-size: 32px;
  padding-left: 8px;
  gap: 5px;
  font-weight: 200;
  font-family: 'Inter', sans-serif;
  flex-grow: 2;
}

.HeaderLogo {
  width: min(160px, 25vw);
}

@media (prefers-color-scheme: dark) {
  .HeaderLeftElement {
    filter: invert(100%);
  }
}

.HeaderRightElement {
  flex-grow: 0;
  margin: 0;
  padding: 0;
}


.HeaderRightElement {
  font-size: 18px;
  flex-grow: .4;
}

.LeftNav {
  display: flex;
  flex-direction: column;
  background-color: #181718;
  color: white;
  overflow-y: scroll;
}

.LeftNav a {
  color: white;
  text-decoration: none;
}

.left-nav-item {
  width: 136px;
  flex: 1;
  padding: 25px 0 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
}


.left-nav-item-available:hover {
  background-color: #96695e;
}

.left-nav-item-blocked {
  color: gray;
}

.left-nav-icon {
  width: 45px;
  height: 45px;
}

.left-nav-icon-available {
  filter: invert(100%);
}

.left-nav-icon-blocked {
  filter: invert(50%);
}


.ProviderMainArea {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  overflow: hidden;
}

.ProviderToolArea {
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
}


.StandardButton {
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.HeadlineText {
  font-size: 28px;
}

/* special non-scrolling tool page; the calendar has a scroller within itself */
.CalendarToolPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.ProviderToolPage {
  text-align: start;
}

.ProviderToolAreaContent {
  flex: 1;
  font-size: 16px;
}

.ProviderToolAreaContent th {
  text-align: start;
  padding: 5px 10px 5px 10px;
}

.ProviderToolAreaContent td {
  padding: 5px 10px 5px 10px;
}

.SectionHeader {
  height: 120px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #96695e;
  z-index: 5;
}

@media (prefers-color-scheme: dark) {
  .BusinessProfileHeader {
    background-color: #423E3A;
  }
}

.ProviderToolAreaScroll {
  flex: 1;
  height: calc(100% - 120px);
  overflow-y: scroll;
}


.SectionHeaderRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}


.SectionHeaderRow h1 {
  flex-grow: 1;
  font-size: 32px;
}

.BusinessProfileSaveSpiner {
  width: 30px;
  height: 30px;
  filter: invert(100%);
}


/* consistently styles groups of input fields */
.InputSection {
  padding: 20px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  background-color: #F6F4F1;
  border: 1px solid #423E3A;
  border-radius: 20px;
}

@media (prefers-color-scheme: dark) {
  .InputSection {
    background-color: #000;
  }
}

.BusinessLocationSection {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.BusinessPhotoSection {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.AddLocationButton {
  background: none;
  border: none;
  text-decoration: underline;
  font-family: Nunito, sans-serif;
  font-size: 16pt;
  font-weight: bold;
  color: #96695e;
  cursor: pointer;
}

.InputError {
  font-size: 14pt;
  color: red;
}

.ServiceBox {
  display: flex;
  flex-direction: column;
  border: 1px solid #423E3A;
  border-radius: 10px;
  padding: 10px;
  gap: 5px;
  font-size: 14pt;
  margin-bottom: 20px;
  color: #000;
  text-decoration: none;
}


.ServiceLength {
  color: #96695e;
  font-style: italic;
}