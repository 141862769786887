.DashboardMain {
  display: flex;
  gap: 25px;
}

.DashboardColumn {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
}

.DashboardBox {
  display: flex;
  flex-direction: column;
  border: 2px solid #423E3A;
  border-radius: 5px;
  padding: 20px;
}

.DashboardBoxHeader {
  font-size: 18px;
  color: #96695e;
  font-weight: bold;
}

.DashboardItemsList {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.DashboardItem {
  padding: 15px 0 15px 0;
  display: flex;
  gap: 5px;
}

.DashboardItemLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.DashboardItemLeft h2 {
  margin: 0;
}

.DashboardItemRight {
  flex: 6;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: start;
  justify-content: start;
  text-align: start;
}

.DashboardItem + .DashboardItem {
  border-top: 1px solid #423E3A;
}