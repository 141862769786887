/* generic element styles across pages */

* {
  box-sizing: border-box;
}


a {
  text-decoration: none;
  color: black;
}

body {
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

/* italicized gray font underneath inputs */
.InputHelpText {
  font-size: 16px;
  font-style: italic;
  color: #423E3A;
}

/* Used for RPC errors typically */
.ErrorText {
  font-size: 14px;
  color: red;
}

h1 {
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: normal;
}

h2 {
  font-family: 'Nunito', serif;
  font-weight: normal;
}

/*
standard action button. this is black in light color schemes 
it should be applied to 'button' elements. it flexes to fill 100% of
the space of the parent.
*/
.ActionButton {
  flex: 1;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 24px 10px 24px;
}

.Inverted {
  background-color: white;
  color: black;
}

/** This should be using in conjunction with ActionButton */
.ActionButtonSecondary {
  background-color: white;
  color: #423E3A;
  border: 1px solid #423E3A;
}

.BrownText {
  color: #96695e;
}

.BrownText a {
  color: #96695e;
  text-decoration: underline;
}

.DarkGrayText {
  color: #423e3a;
}

.DarkGrayText a {
  color: #423e3a;
  text-decoration: underline;
}
